@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.modalButtonWrapper {
  padding: 15px;
  text-align: center;

  .missingButton {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
}

