@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.certificatesContainer {
  visibility: hidden;
  margin-bottom: 20px;

  &.isVisible {
    visibility: inherit;
  }

  @include mqMaxWidth($screenM) {
    display: none;
  }

  @include mqMinWidth($screenL) {
    margin-right: 110px;
  }
}

.certificate {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 80px;
  height: 80px;

  &:last-child {
    margin-right: 0;
  }
}
