@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@/Framework/UI/Organisms/StickyFooter/variables.scss";

.content {
  background-color: $secondaryColorHover;
  height: $smallContentHeight;

  @include mqMaxWidth($screenS) {
    padding: 0;
    height: auto;
  }
}

.contentWidth {
  > div {
    max-width: $finsightScreenXLgMax;
    padding: 0 20px;
    position: relative;
    margin: 0 auto;
  }

  &.footerGroupDivider > div {
    @include mqMaxWidth($screenS) {
      padding: 0;
    }
  }
}

.logoPosition {
  left: 20px;
}

.navigation {
  background-color: #1c1c1c;
}

.linksContainer {
  width: 63.5%;

  @include mqMaxWidth($screenM) {
    width: 100%;
  }
}

.column {
  width: 22.7%;

  &:last-child {
    width: 31%;
  }
}

.columnTitle {
  color: #7d7d7d;
}

.panel {
  border-bottom: 1px solid #616161;
}

.panelArrow {
  fill: #616161;
}

.supportEmailLink {
  max-width: 164px !important;
}
