@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";

.privacyShieldCertificate {
  margin-top: 5px;
  width: 60px !important;
}

.soc2Certificate {
  height: 80px;
  width: 80px;
}

.gdprCompliantHeader {
  @extend .isH3;
  margin: 0 0 15px;
}

.pageContainer {
  max-width: $screenM;
  padding: 50px 30px;

  h3 {
    @extend .isH3;
    margin: 0 0 15px;
    padding-top: 15px;
  }


  p {
    &:not(:last-child) {
      margin: 0 0 20px;
    }

    &:last-child {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  @include mqMaxWidth($screenXXs) {
    padding: 20px;
  }

  table {
    width: 100%;
    border: 1px solid $baseTextColor;
    margin-bottom: 20px;

    td,
    th,
    tr {
      border: 1px solid $baseTextColor;
      padding: 5px;
      text-align: left;
    }
  }
}
