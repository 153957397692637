@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.modalWrp {
  padding: 0;

  :global {
    .modalBody {
      padding: 0;
      display: flex;
    }
    .modalDialog {
      max-width: 800px;

      @include mqMaxWidth($screenXXs) {
        margin-bottom: 0;
      }
    }
  }
}

.modalLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  float: left;
  width: 37%;
  background: $firmColor;
  text-align: center;
  font-family: $titleFontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.logo {
  padding: 40px 68px 0;

  svg {
    fill: #fff;
  }
}

.centerContainer {
  padding: 0;
}

.centeredText {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.backgroundLogo {
  display: block;
  position: absolute;
  z-index: 1;
  width: 995px;
  height: 532px;
  left: -348px;
  top: calc(50% - 356px);

  svg {
    fill: #66468b;
  }
}

.bottomText {
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
  padding-bottom: 40px;

  p {
    font-family: $titleFontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
}

.modalRightSide {
  display: inline-block;
  position: relative;
  float: right;
  vertical-align: top;
  width: 63%;
  height: auto;
  @include mqMaxWidth($screenS) {
    width: 100%;
    height: auto;
  }
}

.closeButton {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  right: 20px;
  top: 20px;
  text-decoration: none;
  border: 0;
  background: 0;
  padding: 0;
  outline: 0;
  fill: $actionColor;
  z-index: 101;

  &:hover {
    fill: $actionColorHover;
  }
}

.hideModalInvisible {
  display: none;
}

.modalDescriptionContainer {
  padding: 20px;
}
