@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/variables.scss";

.resultsContainer {
  display: block;
  width: 100%;
  max-width: $screenL;
  min-height: 104px;
  margin: 0 auto;
  padding: 20px;

  @include mqMaxWidth($screenM) {
    padding: 10px;
    width: auto;
    max-width: none;
  }

  &.noResults {
    padding-bottom: 0;
  }
}

.noSearchResults {
  color: $noSearchResultsTextColor;
  padding: 0 10px;
  text-align: center;

  .searchText {
    color: $headerTextColor;
  }
}

.row {
  position: relative;
  overflow: hidden;
  margin: 0 -10px;
}
