@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.headerLayoutContainer {
  z-index: 4;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $firmColor;
}

.stickyContent {
  width: 100%;
  position: static;
  display: flex;
  flex-direction: column;
}

.stickyContentOpened {
  height: auto;
  max-height: 100vh;
  @include mqMaxWidth($screenM) {
    height: 100vh;
  }
}

.stickyContentFixed {
  position: fixed;
  top: 0;
}

.headerLayoutContainerWithMenu {
  > div {
    display: flex;
    flex-direction: column;
  }
}

.headerLayoutContainerWithMobileMenu {
  > div {
    display: flex;
    flex-direction: column;
  }
}

.headerLayoutStaticContainer {
  position: static;
}

.headerLayoutFixedContainer {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.headerWrapper {
  position: relative;
  z-index: 4;
}

.menuOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.menuWrapper {
  max-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.marketDataMenuWrapper {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

.hideBreadcrumbsWhenHeaderMenuOpened {
  display: none;
}

.minimizeBreadcrumbsWhenMobileScroll {
  @include mqMaxWidth($screenS) {
    height: 0;
    border-bottom: 0;
  }
}
