@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/variables.scss";

.container {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  height: 0;

  @include mqMaxWidth($screenM) {
    flex-direction: column;
    width: 100%;
  }

  &.isSearchFetching {
    overflow: hidden;
  }

  &.isSearchResultsOpen {
    background-color: $menuContentColor;
    height: auto;
  }
}
