@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.uploadInput {
  width: 0;
  height: 0;
}

.errorMessage {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
  margin-left: 20px;
  font-family: $fontFamily;
  font-size: 11px;
  line-height: 13px;
  color: $cancelColor;
}

.uploadContainer {
  font-family: $fontFamily;
  padding-bottom: 30px;
  margin-top: -10px;

  p {
    font-size: 11px;
    color: $secondaryColor;
    margin: 0;
  }
}

.uploadTitle {
  font-family: $fontFamily;
  color: $secondary3Color;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 7px;
}

.inputUploadContainer {
  margin: 10px 0;
}

.fileItem {
  padding: 8px 0 8px 10px;
  display: inline-block;
  max-width: 60%;

  .fileName {
    max-width: 80%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }

  .deleteIconWrapper {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    cursor: pointer;
  }

  .deleteIcon {
    fill: $cancelColor;
    width: 12px;
    height: 12px;
  }
}

.noFileLabel {
  color: $baseTextColor;
  padding-left: 10px;
}
