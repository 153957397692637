@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.successOverlay {
  width: 0;
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  left: -500px;
  background: #fff;
  opacity: 0;
  transition: opacity 1s;
}

.successSubmitContainer {
  position: relative;
  text-align: center;
  font-style: normal;
  width: 100%;
  height: 100%;

  .contentWrapper {
    position: absolute;
    height: 120px;
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-family: $titleFontFamily;
      font-size: 36px;
      font-weight: bold;
      line-height: 39px;
      margin: 0;
    }

    p {
      font-family: $fontFamily;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: $baseTextColor;
    }
  }
}

.succeedSubmit {
  width: 100%;
  opacity: 1;
  left: 0;
}
