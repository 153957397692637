@import "@/Framework/GlobalStyles/variables.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";

.header {
  width: 100%;
  min-height: 200px;
  height: 200px;
  padding-bottom: 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $firmColor;

  @include mqMaxWidth($screenS) {
    min-height: 170px;
    height: 170px;
  }

  @include mqMaxWidth($screenXs) {
    padding-bottom: 20px;
  }
}

.content {
  max-width: $finsightScreenXLgMax;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.headerLogo {
  fill: #fff;
}

.backgroundLogo {
  position: absolute;
  pointer-events: none;
  width: 1125px;
  height: 565px;
  right: -458px;
  bottom: -177px;
  fill: #fff;
  opacity: 0.1;

  @include mqMaxWidth($screenXs) {
    right: -790px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 65px;
  @include mqMaxWidth($screenXs) {
    margin-top: 75px;
  }
}

.title {
  @extend .isH1;
  margin: 0 0 20px;
  color: #fff;

  @include mqMaxWidth($screenS) {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

.tagLine {
  font-size: 14px;
  font-family: $fontFamily;
}

.backgroundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
