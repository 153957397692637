@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.subscriptionsBackground {
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  opacity: 0.1;

  @include mqMaxWidth($screenXs) {
    width: 353px;
    height: 259px;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
