@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";
@import "@/finsight/ui/common/components/header/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.sidebarContainer {
  display: block;
  background-color: $menuSidebarColor;
  width: $menuSidebarWidth;
  min-width: $menuSidebarWidth;
  overflow: auto;

  @include mqMaxWidth($screenM) {
    overflow: visible;
    width: 100%;
    background-color: $menuContentColor;
  }
}

.sectionTitle {
  @extend .title;
  margin: 15px 0 10px 20px;

  @include mqMaxWidth($screenS) {
    margin: 15px 0 10px 25px;
  }

  &.productsTitle {
    @include mqMaxWidth($screenS) {
      margin: 0 0 10px 25px;
    }
  }

  &.regionsTitle {
    margin-top: 30px;

    @include mqMaxWidth($screenM) {
      margin-top: 25px;
    }
  }
}

@mixin sectionItemGroup {
  @include mqMaxWidth($screenM) {
    padding: 0 20px;

    .sectionItem {
      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ .sectionItem {
        width: 33.3333%;
      }

      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4) ~ .sectionItem {
        width: 25%;
      }

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  @include mqMaxWidth($screenS) {
    padding: 0 25px;
  }
}

.sectionItemGroup {
  @include sectionItemGroup;
}

.sectionItemSingle {
  @include sectionItemGroup;

  @include mqMaxWidth($screenM) {
    .sectionItem {
      &:last-child {
        width: 100%;

        @include mqMaxWidth($screenM) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.newBadge {
  width: 32px;
  float: right;

  @include mqMaxWidth($screenM) {
    float: none;
    margin-left: 8px;
  }
}

.sectionItem {
  @extend .link;
  padding: 11px 20px;
  width: 100%;

  &:hover {
    cursor: pointer;
    border-radius: 0;
  }

  &.active {
    background-color: $menuContentColor;
    @include mqMaxWidth($screenM) {
      background-color: $firmColor;
    }
  }

  &:hover,
  &.active {
    .itemAbbreviation {
      display: inline-block;
      @include mqMaxWidth($screenM) {
        display: none;
      }
    }
  }

  @include mqMaxWidth($screenM) {
    background-color: $sidebarSectionItemBackgroundColor;
    display: inline-block;
    text-align: center;
    border: 1px solid $sidebarSectionItemBorderColor;
    border-left: 0;
    width: inherit;
    min-height: 26px;
    padding: 4px 5px;
    line-height: 17px;

    &:first-child {
      border-left: 1px solid $sidebarSectionItemBorderColor;
    }
  }
}

.itemName {
  display: inline-block;
  @include mqMaxWidth($screenM) {
    display: none;
  }
}

.itemAbbreviation {
  display: none;
  white-space: pre;
}

.itemMobileViewName {
  display: none;

  @include mqMaxWidth($screenM) {
    display: inline-block;
  }
}
