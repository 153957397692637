@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.contentWrpOverflowFix {
  overflow: visible;
  .spinnerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $secondary6Color;
    z-index: 3;
  }
}

.layoutChildrenContainer {
  position: relative;
  flex: 1;
}

.layoutChildrenContainerHeight {
  min-height: inherit;
}

.headerAndContentHolder {
  display: flex;
  flex-direction: column;
}

.homePageContainer {
  @include mqMinWidth($screenM) {
    height: 100vh;
  }
}
