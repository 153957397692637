@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.textarea {
  height: 187px;
  resize: none;
}

.inputContainer {
  width: 275px;
}

.headerText {
  margin-bottom: 15px;

  h1 {
    font-family: $titleFontFamily;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
  }

  p {
    font-family: $fontFamily;
    font-size: 14px;
    line-height: 18px;
  }

  .modalDescription {
    margin: 17px 0 0;
    color: $baseTextColor;
  }
}
