@import "@/finsight/ui/common/components/header/variables.scss";
@import "@dealroadshow/uikit/core/styles/links.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.breadcrumbsWrapper {
  width: 100%;
  height: $breadcrumbsWrapperHeight;
  min-height: $breadcrumbsWrapperHeight;
  border-bottom: 1px solid $secondary2Color;
  background-color: $defaultBackgroundColor;
  z-index: 2;
  transition: height $animationTime + ms, border-bottom $animationTime + ms;
}

.breadcrumbsContentWrapper {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  @include mqMaxWidth($screenXs) {
    padding: 0;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.link {
  @extend .link;
  font-size: 12px;
  line-height: 40px;
  margin: 0 10px;
  white-space: nowrap;
}

.parentLinkDiv {
  padding: 0;
  width: 1px;
  height: 12px;
  background-color: $secondaryColor;
}

.breadcrumbsIcon {
  height: 8px;
  width: 6px;
  fill: $secondaryColor;
}

.inactiveLink {
  font-size: 12px;
  line-height: 40px;
  margin: 0 10px;
  color: $secondaryColor;
  white-space: nowrap;
}
