@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";
@import "@/finsight/ui/common/components/header/variables.scss";

.column {
  width: 25%;
  float: left;

  &.noResultsForFirstColumn {
    width: 33.3%;

    @include mqMaxWidth($screenM) {
      width: 100%;
    }
  }

  @include mqMaxWidth($screenM) {
    width: 100%;
  }
}

.title {
  font-weight: 500;
  padding: 10px 10px 5px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: $searchResultsTitleColor;
  letter-spacing: 1px;
}

.columnTitle {
  @extend .title;
  padding: 10px 10px 15px;
}

.resultsBlock {
  margin-bottom: 25px;
}

.resultsItem {
  @extend .link;
  display: block;
  max-width: 320px;

  @include mqMaxWidth($screenM) {
    max-width: 50%;
  }

  @include mqMaxWidth($screenS) {
    max-width: 100%;
  }
}

// extended from /Header/header.scss
.activeResultItem {
  @extend .activeLink;
}

.noResultsBlock {
  color: $noSearchResultsTextColor;
  padding: 0 10px;
}
