@import "@/finsight/ui/common/components/header/variables.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.headerContainer {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: $firmColor;
  z-index: 3;

  @include mqMaxWidth($screenS) {
    &.menuOpenedOnMobileView {
      height: 100%;
    }
  }

  &.transparentBackground {
    background-color: transparent;
  }

  &.userProPlan {
    background: linear-gradient(to right, $firmColor, $firmColor3);
  }
}

.headerTopRow {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 0 auto;
  height: inherit;
  width: 100%;
  padding: 0 20px;
  max-width: $finsightScreenXLgMax;

  @include mqMaxWidth($screenS) {
    align-items: stretch;
    flex-direction: column;
    padding: 0;

    .menuOpenedOnMobileView & {
      overflow: auto;
    }
  }
}

.headerScroll {
  @include mqMaxWidth($screenS) {
    overflow: auto;
  }
}

.headerTopRow > * {
  flex: 1 1 auto;

  @include mqMaxWidth($screenS) {
    flex: none;
  }
}

.headerContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
  max-width: $finsightScreenXLgMax;
  width: 100%;
  min-width: 100%;

  @include mqMaxWidth($screenS) {
    align-items: flex-start;
    height: $headerHeightMobileViewWithSearchInput;
    transition: height $animationTime + ms;

    .hideSearchOnMobileView & {
      height: $headerHeightMobileView;
    }

    .searchResultOpenedOnMobileView &,
    .menuOpenedOnMobileView & {
      height: auto;
      overflow: auto;
    }
  }
}

.hideMarketDataBtnWithOpenedSearchMenu {
  .searchResultOpenedOnMobileView & {
    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}

@mixin menuWrapper {
  overflow: auto;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include mqMaxWidth($screenS) {
    height: 75vh;
  }

  .searchResultOpenedOnMobileView & {
    height: auto;
    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}

.innerMenuWrapper {
  @include menuWrapper;
  width: 100%;
}

.link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: $linkColor;
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: normal;
  padding: 6px 10px;

  &:hover,
  &.active {
    @extend .activeLink;
  }
}

.activeLink {
  background-color: $inputAdbButtonBackgroundColor;
  color: $selectedLinkColor;
  border-radius: 3px;
}

.panelLink {
  @include mqMaxWidth($screenS) {
    display: block;
    min-height: 50px;
    padding: 15px 25px;
    width: 100%;
  }
}

.logo {
  display: flex;
  fill: $headerTextColor;
  height: $headerHeight; // hack for IE11
  min-height: $headerHeight;
  align-items: center;

  @include mqMaxWidth($screenS) {
    position: static;
    margin-left: 15px;
    height: $headerHeightMobileView; // hack for IE11
    min-height: $headerHeightMobileView;
  }
}

.logoWings {
  margin-right: 15px;
  transition: all $animationTime + ms;
  svg {
    width: 50px;
    height: 25px;
  }

  @include mqMaxWidth($screenS) {
    width: 0;
    opacity: 0;
    margin-right: 0;
    svg {
      width: 0;
    }
  }
}

.logoCompanyName {
  height: 14px;
}

.burgerButton {
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  height: 25px;
  width: 16px;
  margin: 8px 0;
  cursor: pointer;
  fill: $headerTextColor;
  opacity: 0;
  transition: width $animationTime + ms, opacity $animationTime + ms;

  @include mqMaxWidth($screenS) {
    width: 16px;
    opacity: 1;
  }
}

.toolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: $headerHeight;
  height: $headerHeight; // hack for IE11
  transition: all $animationTime + ms;

  @include mqMaxWidth($screenS) {
    width: 100%;
    flex-direction: column;
    min-height: auto;
    height: auto; // hack for IE11
  }
}

.linksButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @include mqMaxWidth($screenS) {
    position: static;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }

  &.premiumizationLinksContainer {
    @include mqMinWidth($screenM) {
      width: 442px;
      flex-grow: 0;

      >div:first-child {
        display: flex;
        flex-wrap: nowrap;
        a {
          padding: 6px 15px;

          &:last-of-type {
            margin-right: 15px;
          }
        }
      }
    }

    flex-grow: 1;
    width: auto;
  }
}

.solutionsButton {
  @extend .link;
  @extend .panelLink;
  color: $headerTextColor;
  line-height: 16px;
  border: 0;

  &:hover {
    color: $headerTextColor;
  }

  @include mqMinMaxWidth($screenS, $screenM) {
    display: none;
  }
}

.iconContainer {
  i {
    display: none;
    margin-right: 15px;
    fill: $supportedColor;
    float: left;
  }

  @include mqMaxWidth($screenS) {
    i {
      display: inline-block;
    }
  }
}

.iconContainerPremiumization {
  i {
    fill: #fff;
    width: 12px;
    margin-right: 6px;
    float: left;
  }

  span {
    font-weight: 700;
  }

  @include mqMaxWidth($screenS) {
    display: inline-block;
    padding: 10px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-weight: 700;
  }
}

.spinnerOverlay {
  // Spinner styles are importing beneath and overwrites the current
  // That's why this is !important
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  border-top-color: rgba(255, 255, 255, 0.6) !important;
}

.title {
  font-family: $fontFamily;
  color: $marketDataMenuTitleColor;
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.beta,
.new {
  color: $menuNewAndBetaTagColor;
  padding-left: 3px;
  font-weight: normal;
}
