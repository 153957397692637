@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";
@import "@/finsight/ui/common/components/header/variables.scss";

.toolWrp {
  width: 25%;

  @media only screen and (min-width: $screenS) and (max-width: $screenM) {
    width: 50%;
  }

  @include mqMaxWidth($screenS) {
    width: 100%;
  }
}
.tool {
  @extend .link;
  position: relative;
  margin: 3px 0;
  padding: 8px 10px 8px 36px;
  display: block;
  max-width: 270px;

  @media only screen and (min-width: $screenS) and (max-width: $screenM) {
    max-width: 270px;
  }

  @include mqMinWidth($screenL) {
    max-width: 270px;
  }

  @include mqMaxWidth($screenS) {
    padding: 8px 32px;
    max-width: 100%;
    margin: 5px 0;
  }

  &:hover .toolsIcon,
  &.activeLink .toolsIcon {
    fill: #fff;
  }
}
.toolsExternalIcon {
  position: absolute;
  top: 9px;
  right: 10px;
  fill: rgba(255, 255, 255, 0);
}

.activeLink {
  @extend .activeLink;
  .toolsExternalIcon {
    fill: rgba(255, 255, 255, 0.2);
  }
}

.toolsIcon {
  position: absolute;
  top: 9px;
  left: 10px;
  fill: $linkColor;

  @include mqMaxWidth($screenS) {
    top: 11px;
    width: 12px;
    height: 12px;
  }
}

.userPlanTag {
  margin-left: 5px;
}
