@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/variables.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";

/* MarketDataMenuButton */

.marketDataButton {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  color: $headerTextColor;
  height: 28px;
  line-height: 16px;
  border: 0;
  padding: 6px 23px 6px 10px;

  @include mqMaxWidth($screenS) {
    display: block;
    min-height: 50px;
    width: 100%;
    margin: 0;
    padding: 15px 25px;
    text-align: left;
  }

  &.isMobileMenuHidden {
    @include mqMaxWidth($screenS) {
      display: none;
    }
  }

  &:hover,
  &.isActive {
    background-color: $inputAdbButtonBackgroundColor;
    color: $headerTextColor;
    border-radius: 3px;
    @include mqMaxWidth($screenS) {
      background-color: $menuContentColor;
    }
  }

  &.isActive {
    @include mqMaxWidth($screenS) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.toggleIcon {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 12px;
  right: 8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid $headerTextColor;
  transition: $animationTime + ms linear;

  &.open {
    transform: rotate(180deg);
  }

  @include mqMaxWidth($screenS) {
    display: block;
    width: 10px;
    height: 10px;
    top: 50%;
    right: 25px;
    border: 0;
    transform: translateY(-50%);

    &:before,
    &:after {
      border-radius: 1px;
      display: block;
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      top: 0;
      width: 2px;
      left: 4px;
      height: 100%;
      transition: transform $animationTime + ms linear;
    }

    &:after {
      top: 4px;
      left: 0;
      height: 2px;
      width: 100%;
    }

    &.open {
      transform: translateY(-50%);
    }

    &.open:before {
      transform: rotate(90deg);
    }
  }
}

/* MarketDataMenu */

.container {
  position: relative;
  display: none;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background-color: $menuContentColor;

  @include mqMinWidth($screenS) {
    overflow: auto;
  }

  @include mqMaxWidth($screenM) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  &.isMarketDataMenuOpen {
    display: flex;
  }
}

.spinnerOverlay {
  @extend .spinnerOverlay;
  position: static;
  min-height: 100px;
}
