@import "@/finsight/ui/common/components/header/variables.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.headerLinkItemContainer {
  position: relative;
}

.toggleIconWrapper {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  .toggleIcon {
    position: relative;
    width: 9px;
    height: 9px;
    transition: $animationTime + ms linear;

    &:before,
    &:after {
      border-radius: 1px;
      display: block;
      content: '';
      position: absolute;
      background-color: $supportedColor;
    }

    &:before {
      top: 0;
      width: 1px;
      left: 4px;
      height: 100%;
      transition: transform $animationTime + ms linear;
    }

    &:after {
      top: 4px;
      left: 0;
      height: 1px;
      width: 100%;
    }

    &.open:before {
      transform: rotate(90deg);
    }

  }
  @include mqMaxWidth($screenS) {
    display: flex;
  }
}

.externalLink {
  position: relative;
}

.externalIcon {
  position: absolute;
  top: 9px;
  right: 10px;
  fill: rgba(255, 255, 255, 0);
}

.externalLink:hover {
  .externalIcon {
    fill: rgba(255, 255, 255, 0.2);
  }
}
