@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/finsight/ui/common/components/header/variables.scss";

.pageContainer {
  background-color: $defaultBackgroundColor;
  width: 100%;
  flex-grow: 1;
  min-height: inherit;
}

.pageContentHolder {
  margin: 0 auto;
  height: inherit;
  width: 100%;
  padding: 0 20px;
  max-width: $finsightScreenXLgMax;

  @include mqMaxWidth($screenM) {
    max-width: 100%;
  }

  @include mqMaxWidth($screenXs) {
    padding: 0 10px;
  }
}

.homePageContainer {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;

  background-color: $firmColor;

  @include mqMaxWidth($screenM) {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &:after {
    content: '';
    background: $firmColor;
    transform: rotate(20deg);
    position: absolute;
    height: 229%;
    width: 68%;
    left: -12%;
    top: -101%;
    z-index: 2;
    @include mqMaxWidth($screenM) {
      width: 88%;
    }
    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}

.homePageContentHolder {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 0 auto;
  height: inherit;
  width: 100%;
  padding: 0 20px;
  max-width: $finsightScreenXLgMax;

  @include mqMaxWidth($screenM) {
    max-width: 100%;
  }

  @include mqMaxWidth($screenXs) {
    padding: 0 10px;
  }
}

.staticPageContentHolder {
  @extend .pageContentHolder;
  max-width: 100%;
}

.contentWrpOverflowFix {
  overflow: visible;
}
