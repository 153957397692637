$linkColor: #5fa0ff;
$selectedLinkColor: #fff;
$headerTextColor: #fff;
$marketDataMenuTitleColor: #fff;
$menuContentColor: #302046;
$menuSidebarColor: #28193b;
$spinnerBackgroundColor: #301f47;
$placeholderTextColor: rgba(255, 255, 255, 0.4);
$panelBorderColor: rgba(255, 255, 255, 0.1);
$inputAdbButtonBackgroundColor: rgba(0, 0, 0, 0.2);
$sidebarSectionItemBackgroundColor: #2d2047;
$sidebarSectionItemBorderColor: #5f467c;
$searchResultsTitleColor: #9984b3;
$noSearchResultsTextColor: #948f9b;
$menuNewAndBetaTagColor: #d1389b;

$animationTime: 200;

$menuSidebarWidth: 260px;
$searchInputHeight: 28px;

$headerZIndex: 2;

$headerHeight: 48px;
$headerHeightMobileView: 40px;
$breadcrumbsWrapperHeight: 42px;
$headerHeightMobileViewWithSearchInput: $headerHeightMobileView * 2;

:export {
  headerHeight: $headerHeight;
  headerHeightMobileView: $headerHeightMobileView;
  headerHeightMobileViewWithSearchInput: $headerHeightMobileViewWithSearchInput;
  animationTime: $animationTime;
  breadcrumbsWrapperHeight: $breadcrumbsWrapperHeight;
}
