@import "@/finsight/ui/common/components/header/variables.scss";

$headerHeight: $headerHeight;
$headerHeightMobile: $headerHeightMobileViewWithSearchInput;
$marketOverviewTopPanelsHeight: 508px;
$marketOverviewTopPanelsHeightWithoutMargin: 468px;

$baseBackgroundColor: #eee;

// Large screen / wide desktop
$finsightScreenLgMin: 1200px !default;
$finsightScreenXLgMax: 1600px !default;

:export {
  finsightScreenLgMin: $finsightScreenLgMin;
  finsightScreenXLgMax: $finsightScreenXLgMax;
}
