@import "@/finsight/ui/common/components/header/Header/header.scss";

.profileContainer {
  margin-bottom: 30px;
  position: relative;

  @include mqMaxWidth($screenS) {
    margin: 0;
    padding: 0 10px;
  }

  &.childrenHidden {
    @include mqMaxWidth($screenS) {
      .childProfile {
        display: none;
      }
    }
  }
}

.profileWrp {
  @include mqMaxWidth($screenS) {
    padding: 5px 0;
  }
}

.profileContainerExpanded {
  @include mqMaxWidth($screenS) {
    background-color: #261a38;
  }
}

.parentProfile {
  @extend .link;
  @extend .panelLink;
  display: block;
  padding: 8px 10px 8px 36px;
  font-weight: bold;

  @media only screen and (min-width: $screenS) and (max-width: $screenM) {
    max-width: 270px;
  }

  @include mqMinWidth($screenL) {
    max-width: 270px;
  }

  @include mqMaxWidth($screenS) {
    position: relative;
    padding: 8px 15px;
    width: 100%;
    min-height: auto;
    height: auto;

    &.isExpanded {
      border-bottom: 0;
    }
  }
}

.childrenProfilesRow {
  @include mqMaxWidth($screenS) {
    display: none;

    &.isExpanded {
      display: block;
    }
  }
}

.childProfile {
  @extend .link;
  display: block;
  padding: 8px 10px 8px 36px;

  @media only screen and (min-width: $screenS) and (max-width: $screenM) {
    max-width: 270px;
  }

  @include mqMinWidth($screenL) {
    max-width: 270px;
  }
}

.disabledProfile {
  opacity: 0.4;

  &:hover {
    background-color: transparent;
    border: 0;
    cursor: default;
  }
}

.active {
  .parentProfile {
    @extend .activeLink;
  }

  .childProfile {
    @extend .activeLink;
  }
}

.sectorIndicator {
  position: absolute;
  top: 12px;
  left: 10px;
  float: left;
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-radius: 2px;

  @include mqMaxWidth($screenS) {
    position: relative;
    top: 4px;
    left: 2px;
  }
}
