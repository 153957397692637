@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/variables.scss";


.searchContainer {
  position: relative;
  height: $searchInputHeight;
  margin-left: 10px;
  display: block;
  transition: all $animationTime + ms;

  @include mqMaxWidth($screenS) {
    order: -1;
    margin: 2px 0 10px;
    width: 100%;
    padding: 0 15px;
  }
}


.searchInputWrapper {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.4);

  &.searchIconIsActive {
    .searchIcon svg {
      fill: $headerTextColor;
    }
  }

  &:hover {
    .searchInput {
      background-color: rgba(0, 0, 0, 0.7);
    }

    .searchIcon {
      fill: $headerTextColor;
    }
  }

  .searchInput {
    display: inline-block;
    vertical-align: top;
    border: 0;
    background-color: transparent;
    border-radius: 3px;
    color: $headerTextColor;
    min-width: 100px;
    width: 340px;
    height: $searchInputHeight;
    padding: 0 12px 0 36px;
    transition: background-color $animationTime + ms;

    &:focus {
      background-color: rgba(0, 0, 0, 0.7);
    }

    @include mqMaxWidth($screenM) {
      width: 270px;
    }

    @include mqMaxWidth($screenS) {
      width: 100%;
    }

    &::placeholder {
      color: $placeholderTextColor;
    }
  }
}

.searchIconContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}

.searchIcon {
  margin-top: 6px;
  width: 12px;
  fill: $placeholderTextColor;
  transition: fill $animationTime + ms;
}
