@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/variables.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";

.contentContainer {
  display: block;
  flex: 1 1 auto;
  overflow: auto;
  padding: 15px 30px 0;
  background-color: $menuContentColor;

  @include mqMaxWidth($screenM) {
    width: 100%;
    padding: 10px;
    overflow: visible;
  }

  @include mqMaxWidth($screenS) {
    padding: 10px 25px 0;
  }
}

.toolsTitle {
  @extend .title;
  margin: 0;
  padding: 0 0 10px 10px;

  @include mqMaxWidth($screenM) {
    margin-top: 15px;
  }

  @include mqMaxWidth($screenS) {
    padding: 0 0 5px;
    margin-left: 25px;
  }
}

.sectorsTitle {
  @extend .toolsTitle;
  margin-top: 30px;

  @include mqMaxWidth($screenS) {
    margin: 10px 25px 0;
    padding-bottom: 0;
  }
}

.row {
  @media only screen and (min-width: $screenS) and (max-width: $screenM) {
    margin: 0;
  }

  @include mqMaxWidth($screenS) {
    margin: 0 -25px;
  }
}

.equityRow {
  display: flex;
  height: 71vh;

  > div {
    height: 470px;
    padding-bottom: 25px;
  }

  @include mqMaxWidth($screenM) {
    display: block;
  }

  @include mqMaxWidth($screenS) {
    height: auto;
    margin-left: -8px;

    > div {
      height: auto;
    }
  }

  a {
    padding: 8px 36px 8px 8px;
  }
}

.capEdgeTooltip {
  position: relative;
  padding: 10px 28px 14px 14px;
  margin: 5px 8px 20px;
  color: #fff;
  border: 1px solid #fff;

  @include mqMaxWidth($screenM) {
    margin: 20px 0;
  }

  i {
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 6px;
  }

  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }

  &.closed {
    display: none;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  a:hover {
    color: #fff;
  }
}

.subRow {
  @extend .row;
  @include mqMaxWidth($screenS) {
    margin: 0;
  }
}

.col {
  display: inline-block;
  width: 50%;
  float: left;

  @include mqMaxWidth($screenS) {
    display: block;
    float: none;
    width: 100%;
  }
}

.subCol {
  @extend .col;
  @include mqMaxWidth($screenM) {
    width: 100%;
  }
}

.toolsWrp {
  display: flex;
  flex-wrap: wrap;

  @include mqMaxWidth($screenS) {
    flex-direction: column;
    margin: 0 -10px;
  }
}
