@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/common/components/header/Header/header.scss";
@import "@/finsight/ui/common/components/header/variables.scss";


.logInButton {
  @extend .link;
  @extend .panelLink;
  color: $headerTextColor;
  line-height: 16px;

  &:hover {
    color: $headerTextColor;
  }

  @include mqMaxWidth($screenS) {
    position: static;
    bottom: 0;
    left: 0;
    border-bottom: 0;
    padding: 15px 25px;
  }
}

.premiumizationLoginButton {
  @extend .logInButton;
  min-height: auto;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @include mqMaxWidth($screenS) {
    padding: 20px;
  }
}

.logInBtnSpinnerWrapper {
  min-width: 58px;
  padding-left: 10px;

  @include mqMaxWidth($screenS) {
    width: 100%;
    margin: 0;
    padding: 15px 25px;
  }
}

.logInBtnSpinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.menuWrapper {
  min-width: 58px;

  @include mqMaxWidth($screenS) {
    width: 100%;
    padding: 15px 25px;
  }
}

.name {
  display: none;
  @include mqMaxWidth($screenS) {
    display: block;
  }
}

.avatar {
  margin: 0 auto;
  @include mqMaxWidth($screenS) {
    margin: 0;
  }
}
